<template>
  <lab-task><lab-synthesis-of-alum :yield-factor="yieldFactor" /></lab-task>
</template>

<script>
import Vue from 'vue';
import LabSynthesisOfAlum from '../simulations/LabSynthesisOfAlum';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import LabTask from './labs/LabTask';
export default Vue.extend({
  name: 'SynthesisOfAlum',
  components: {LabSynthesisOfAlum, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    yieldFactor() {
      return this.taskState.getValueBySymbol('yieldFactor').content.toFloat();
    },
  },
});
</script>
